const clickOutside = {
  bind (el, binding) {
    const handleClickOutside = event => {
      if (!el.contains(event.target)) {
        binding.value(event)
      }
    }

    document.addEventListener('click', handleClickOutside)
    el.__vueClickOutside__ = handleClickOutside
  },

  unbind (el) {
    document.removeEventListener('click', el.__vueClickOutside__)
    delete el.__vueClickOutside__
  }
}

export default clickOutside
